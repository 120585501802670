import React from 'react'
import KeithGraham from "../assets/photos/keith-2.JPG";
import TarigAbdalla from "../assets/photos/tarig-1.JPG";
import moa from "../assets/photos/mod-1.JPG";

function Team() {
    return (
        <>
        <div className="page__title-container">
            <p className="page__title">Meet the Team</p>
            </div>
            <section className="team">
                <div className="team__physicians">
                <div className="team__header">
                    <p>Our Physicians</p>
                </div>
                <div className="team__doc">
                        <p className="team__doc-name">Dr. Keith Graham</p>
                        <img className="team__doc-img" src={KeithGraham} alt="Dr. Keith Graham" />
                        <p className="team__doc-bio">Dr. Keith Graham has practiced medicine on Quadra Island since 2008, after working in Campbell River and practicing at both the Campbell River and District hospital from 1993-2007, and Cowichan and District hospital prior to that. He graduated from the University of British Columbia in 1988 with a degree in Biochemistry and in 1992 with a Doctor of Medicine.
                        He is a member of the Faculty of Medicine of UBC, and functions as a Clinical Instructor, teaching medical students in their third year of training. He was raised in Campbell River and graduated from Carihi in 1975. </p>
                        <p className="team__doc-bio">His main interests professionally, are all aspects of family medicine.</p> 
                        <p className="team__doc-bio">In his spare time, he enjoys spending time with his family, and being entertained by his Daschund, Auggie.</p>
                </div>
                <div className="team__doc">
                        <p className="team__doc-name">Dr. Tarig Abdalla</p>
                        <img className="team__doc-img" src={TarigAbdalla} alt="Dr. Tarig Abdalla" />
                        <p className="team__doc-bio">Dr. Tarig Abdalla graduated from Sudan and was trained in Saudi Arabia, where he also practiced since 2012. He came to Canada in 2020 for higher training where he completed a fellowship at the University of Toronto, before moving with his wife and children to British Columbia.&nbsp;</p>
                        <p className="team__doc-bio">His main interest professionally is the management of all aspects of medicine, especially diabetes, and endocrinology. He loves to work closely with patients to help them achieve optimal health outcomes and prevent complications. He takes a patient-centered approach to medicine, working closely with his patients to develop individualized treatment plans that take into account their unique needs and goals.</p>
                        <p className="team__doc-bio">During his free time, Dr. Abdalla enjoys spending time with his family and pursuing his passion for photography.</p>
                        
                    </div>
                </div>
                <div className="team__header team__header--moa">
                    <p>Our MOAs</p>
                </div>
                <div className="team__moa">
                <div className="team__moa-card">
                        <img className="team__moa-img" src={moa} alt="Medical Office Assistants" />
                        <div className="team__moa-bio">
                    <p >
                        Meet our team of MOAs (Medical Office Assistants) – who look after all the administrative duties and patient appointments within our clinic.  They all bring something different to the table from past experiences, but share the same enthusiasm for the job and a love of helping people.
                    </p>
                    <p>
                        Deanna (Office Manager), Coleen, Siân and Tucker welcome you to Harbourside Medical Clinic.
                    </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Team
