import React from 'react'

function Fees() {
    return (
        <>
            <div className="page__title-container">
            <p className="page__title">Fees</p>
            </div>
            <section className="fees">
                
                <div className="fees__not-covered">
                <div className="fees__header">
                <p>Services that are NOT covered by</p>
                <p>MSP Health Coverage</p>
                </div>
                    <div className="fees__left">
                        <p className="fees__p">Drivers Medical</p>
                        <p className="fees__p">Seniors Drivers Medical</p>
                        <p className="fees__p">Seafarer's Medical*</p>
                        <p className="fees__p">First Aid / School Medical</p>
                        {/* <p className="fees__p">Photocopies of Medical Records</p> */}
                    </div>
                    <div className="fees__right">
                        <p className="fees__p">$80.00</p>
                        <p className="fees__p">$50.00</p>
                        <p className="fees__p">$185.00</p>
                        <p className="fees__p">$50.00</p>
                        {/* <p className="fees__p">$1/pg</p> */}
                    </div>
                    <p class="fees__not-covered-note">*Dr. Graham only</p>
                </div>
                
                <div className="fees__covered">
                    <div className="fees__header">
                <p>Service fees for those without MSP</p>
                <p>Health Coverage</p>
                </div>
                    <div className="fees__left">
                        <p className="fees__p fees__p--bottom">Regular Office Visit</p>
                        <p className="fees__p fees__p--bottom">Complete Physical</p>
                        <p className="fees__p fees__p--bottom">Consultations</p>
                    </div>
                    <div className="fees__right">
                        <p className="fees__p fees__p--bottom">$80.00</p>
                        <p className="fees__p fees__p--bottom">$150.00</p>
                        <p className="fees__p fees__p--bottom">$100.00</p>
                    </div>
                    <div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Fees
