import React from 'react';
import { Link, } from "react-router-dom";
import logo from "../assets/logos/logo-color-text.png";
import logodt from "../assets/logos/logo-site-color.png";
import phoneicon from "../assets/icons/phone-white.svg";
import fbicon from "../assets/icons/facebook-white.svg";

const Header = props => {
    return (
        <>
            <section className="header">
                <Link to={`/home`} className="header__logo-mobile">
                    <img
                        className="header__logo-img"
                        alt="Harbourside Medical Clinic"
                        src={logo}
                    />
                </Link>
                <div className="header__dt">
                    <div className="header__top">
                        <div className="header__top-phone">
                            <img className="header__top-icon" src={phoneicon} alt="Phone" />
                            <p className="header__top-phone-text"><a href="tel:250-285-2265">(250) 285-2265</a></p>
                        </div>
                        <div className="header__top-fb">
                            <a href="https://www.facebook.com/Harbourside-Medical-Clinic-109705530723902"><img className="header__top-icon" src={fbicon} alt="Facebook"/></a>
                        </div>
                    </div>
                <Link to={`/home`} className="header__logo-dt">
                    <img
                        className="header__logo-dt-img"
                        alt="Harbourside Medical Clinic"
                        src={logodt}
                    />
                    <h1 className="header__title">Harbourside Medical Clinic</h1>
                    </Link>
                    <div className="header__nav-dt">
                        <nav className="header__nav-list-dt">
                            <Link to={`/home`} className="header__nav-link-dt">Home</Link>
                                <Link to={`/resources`} className="header__nav-link-dt">Patient Resources</Link>
                                <Link to={`/team`} className="header__nav-link-dt">Meet the Team</Link>
                                <Link to={`/faq`} className="header__nav-link-dt">FAQ</Link>
                                <Link to={`/fees`} className="header__nav-link-dt">Fees</Link> 
                    </nav>
                    </div>
                </div>
                {/* <div className="header__navburger"> */}
                    {props.menuOpen === false ?
                        (<div className="header__navburger" onClick={() => props.handleMenuClick()}>
                            <div className="header__hamburger-icon"
                        
                            ></div>
                        </div>) :
                        (<div className="header__navburger" onClick={() => props.handleCloseMenuClick()}>
                            <div
                            className="header__hamburger-icon"
                            
                            ></div>
                            <div className="header__nav-list"> 
                                <Link to={`/home`} className="header__nav-link"
                                onClick={() => props.handleCloseMenuClick()}>Home</Link>
                                <Link to={`/resources`} className="header__nav-link"
                                onClick={() => props.handleCloseMenuClick()}>Patient Resources</Link>
                                <Link to={`/team`} className="header__nav-link"
                                onClick={() => props.handleCloseMenuClick()}>Meet the Team</Link>
                                <Link to={`/faq`} className="header__nav-link"
                                onClick={() => props.handleCloseMenuClick()}>FAQ</Link>
                                <Link to={`/fees`} className="header__nav-link"
                                onClick={() => props.handleCloseMenuClick()}>Fees</Link> 
                            </div>
                            </div>
                        )}
                {/* </div> */}
            </section>
            
        </>
    )
}

export default Header;
