import React from 'react'
import { Link, } from "react-router-dom";
import facebookicon from "../assets/icons/facebook-white.svg";
import logo from "../assets/logos/hsm-logo-white.png"

function Footer() {
    return (
        <section className="footer"> 
            <div className="footer__container">
                <div className="footer__top">
                    <div className="footer__item">
                        <p className="footer__title">Contact Us</p>
                        <p><a href="tel:250-285-2265">(250) 285-2265</a></p>
                        <p><a href="mailto:office@hrbmc.ca">office@hrbmc.ca</a></p>
                    </div>
                    <div className="footer__item">
                        <p className="footer__title">Social</p>
                        <p className="footer__nav-link">Follow us!</p>
                        <a className="footer__nav-link" href="https://www.facebook.com/Harbourside-Medical-Clinic-109705530723902">
                        <img src={facebookicon} alt="Facebook"/></a>
                    </div>
                    <div className="footer__item">
                        <p className="footer__title">Address</p>
                        <p>672 Plaza Rd</p>
                        <p>PO Box 250</p>
                        <p>Quathiaski Cove, BC</p>
                        <p>V0P 1N0</p>
                    </div>
                </div>
                <div className="footer__bottom">
                    <img className="footer__logo" src={logo} alt="Harbourside Medical" />
                    <p className="footer__designer">Website by Shelby Mitchell</p>
                    <p className="footer__designer">© Harbourside Medical Clinic 2020. All Rights Reserved.</p>
                </div>
                <div className="footer__pages">
                    <p className="footer__title">Pages</p>
                    <nav className="footer__nav-list">
                        <div className="footer__nav-half">
                            <Link to={`/home`} className="footer__nav-link">Home</Link>
                            <Link to={`/resources`} className="footer__nav-link">Patient Resources</Link>
                        </div>
                        <div className="footer__nav-half ">
                            <Link to={`/team`} className="footer__nav-link">Meet the Team</Link>
                            <Link to={`/faq`} className="footer__nav-link">FAQ</Link>
                            <Link to={`/fees`} className="footer__nav-link">Fees</Link>
                        </div> 
                    </nav>
                </div>
            </div>
        </section>
    )
}

export default Footer
