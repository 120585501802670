import React from 'react'
import phoneicon from "../assets/icons/phone-blue-circle.svg";
import NewPatientForm from "../assets/documents/NewPatientForm.pdf";

function Resources() {
    return (
        <>
        <div className="page__title-container">
            <p className="page__title">Patient Resources</p>
        </div>
        <section className="resources">
                <div className="resources__forms">
                    {/* <div className="resources__header">
                        <p>Forms &amp; </p>
                    </div> */}
                    <div className="resources__button resources__button-blue">
                        <a className="resources__button-link" href = {NewPatientForm} target="_blank" rel="noopener noreferrer"><p className="resources__link-text">New Patient Form</p></a>
                    </div>
                    {/* <div className="resources__button resources__button-blue">
                        <a className="resources__button-link" href="">
                            <p className="resources__link-text">Consent to Use Electronic</p>
                            <p className="resources__link-text">Communications Forms</p>
                        </a>
                    </div> */}
                    <div className="resources__button resources__button-blue">
                        <a className="resources__button-link" href="https://doxy.me/">
                            <p className="resources__link-text">Virtual Appointments with doctors</p>
                            <p className="resources__link-text">available online at www.doxy.me</p>
                        </a>
                    </div>
                </div>
                <div className="resources__other">
                    <div className="resources__header resources__header--other">
                        <p>Other Service Links</p>
                    </div>
                    <div className="resources__button resources__button--other">
                        <a className="resources__button-link" href="https://www.islandhealth.ca/">
                            <p className="resources__link-text resources__link-text--other">www.islandhealth.ca</p>
                            <p className="resources__link-text resources__link-text--other-desc">General Vancouver Island health information</p>
                        </a>
                    </div>
                    <div className="resources__button resources__button--other">
                        <a className="resources__button-link" href="https://campbellriver.fetchbc.ca/">
                            <p className="resources__link-text resources__link-text--other">www.campbellriver.fetchbc.ca</p>
                            <p className="resources__link-text resources__link-text--other-desc">Local community services resource information</p>
                        </a>
                    </div>
                    <div className="resources__button resources__button--other">
                        <a className="resources__button-link" href="https://foundrybc.ca/">
                            <p className="resources__link-text resources__link-text--other">www.foundrybc.ca</p>
                            <p className="resources__link-text resources__link-text--other-desc">Support for mental health and substance use for Youths age 12-24</p>
                        </a>
                    </div>
                    <div className="resources__button resources__button--other">
                        <a className="resources__button-link" href="https://pathwaysmedicalcare.ca/">
                            <p className="resources__link-text resources__link-text--other">pathwaysmedicalcare.ca</p>
                            <p className="resources__link-text resources__link-text--other-desc">Pathways Medical Care Directory</p>
                        </a>
                    </div>
                </div>
                <div className="resources__local">
                    <div className="resources__header resources__header--local">
                        <p>Quadra Healthcare Support</p>
                    </div>
                    <div className="resources__local-card-container">
                    <div className="resources__local-card resources__local-card--left">
                        <div className="resources__local-card-lbl">
                            <p>Campbell River Hospital</p><p>Outpatient Lab Services</p>
                        </div>
                        <div className="resources__local-card-content">
                            <ul className="resources__ul">
                                <li>Located at Quadra Island Medical Clinic</li>
                                <li>Available to all Quadra Island Residents</li>
                                <li>Wednesdays and Friday mornings (except Stats), <a href="https://waitwhile.com/book/quadralab/calendar">by appointment</a></li>
                                <li>Physicians Requisition Form and Care Card Required</li>
                                <li><a href="https://waitwhile.com/book/quadralab/calendar">Book Appointment Here</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="resources__local-card">
                        <div className="resources__local-card-lbl">
                            <p>Cove Pharmacy</p>
                            <div className="resources__local-contact">
                                <img className="resources__local-phone" src={phoneicon} alt="250-285-2275" />
                                <p>250-285-2275</p>
                            </div>
                        </div>
                        <div className="resources__local-card-content">
                            <ul className="resources__ul">
                                <li>Conveniently located beside Harbourside Medical Clinic</li>
                                <li>Medication reviews</li>
                                <li>Medication blister packaging</li>
                                <li>Travel clinic consultations</li>
                                <li>Flu and shingles vaccinations</li>
                                <li>Smoking cessation program</li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
    )
}

export default Resources;
