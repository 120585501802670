import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/main.css";

import Header from "./components/Header";
import Home from "./components/Home";
import Resources from "./components/Resources";
import Team from "./components/Team";
import Faq from "./components/Faq";
import Fees from "./components/Fees";
import Footer from "./components/Footer";


export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  // componentDidMount() {
  //   this.setState({
  //     menuOpen: false
  //   });
  // }
    

  handleMenuClick = () => {
    this.setState({ menuOpen: true });
  }

  handleCloseMenuClick = () => {
    this.setState({ menuOpen: false });
  }

  render() {
  return (
    <>
      <Router>
        <Header
          menuOpen={this.state.menuOpen}
          handleMenuClick={this.handleMenuClick}
          handleCloseMenuClick={this.handleCloseMenuClick} />
        <Switch>
          <Route path="/resources"><Resources /></Route>
          <Route path="/team"><Team /></Route>
          <Route path="/faq"><Faq /></Route>
          <Route path="/fees"><Fees /></Route>
          <Route path="/"><Home /></Route>
        </Switch>
        <Footer />
      </Router>
      </>
  );
}
}
  
  

